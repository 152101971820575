<template>
  <div class="contact container has-text-centered">
    <div v-if="pending" class="columns">
        <div class="column">
           
            <div class="columns box">
                <div class="column">
                     <b-image
                        :src="$axios.defaults.baseURL + contact.Logo.url"
                        alt="A random image"
            ></b-image>
            <h1 class="title"> {{this.title}}</h1>
            <vue-simple-markdown :source="source"></vue-simple-markdown>
            </div>
            <div class="column">
                <gmaps-map :options="mapOptions">
                    <gmaps-marker :position="{ lat: 48.52880425527273, lng:  15.784954792131568 }" />
                </gmaps-map>
            </div>
    </div>
    </div>
    
    </div>
    <div v-else class="box">
              <h1 class="title"> Loading . . .</h1>
    </div>
    
  </div>
</template>

<script>
import { gmapsMap, gmapsMarker } from 'x5-gmaps';
export default {
    name:"contact",
    components: { gmapsMap, gmapsMarker },
    data(){
        return{
            pending: false,
            contact: null,
            mapOptions: {
                center: { lat: 48.52880425527273, lng:  15.784954792131568 },
                zoom: 12,
            },
        }
    },
    computed:{
        title(){
            return this.contact.Title
        },
        source(){
            return this.contact.Kontakt
        }
    },
    async mounted(){
        this.pending = false
        this.contact = await this.$get("/kontakt")
        this.pending = true
    }
    
}
</script>

<style>

</style>